<template>
  <Carousel :items-to-show="1" :wrap-around="true">
    <Slide v-for="listing in listings" :key="listing.id">
      <div v-if="listing.id === 1" class="carousel__item" v-bind:style="{ backgroundImage: 'url(' + 'data:image/jpeg;base64,'+ listing.featuredImage + ')' }"><span class="slider-title">{{ listing.title }}</span></div>
      <div v-else @click="goToCompetition(listing.id)" class="carousel__item" v-bind:style="{ backgroundImage: 'url(' + 'data:image/jpeg;base64,'+ listing.featuredImage + ')' }"><span class="slider-title">{{ listing.title }}</span></div>

    </Slide>

    <template #addons>
      <Navigation/>
      <Pagination/>
    </template>
  </Carousel>

</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'WrapAround',
  props: {
    listings: {
      type: Array
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
      goToCompetition: function (id) {
          location.href='/competition/' + id
      }
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // // 700px and up
      // 700: {
      //   itemsToShow: 3.5,
      //   snapAlign: 'center',
      // },
      // // 1024 and up
      // 1024: {
      //   itemsToShow: 5,
      //   snapAlign: 'start',
      // },
    },
  }),
});
</script>

<style>
.carousel__item {
  min-height: 550px;
  width: 100%;
  background-color: #4FD1C1;
  color:  var(--carousel-color-white);
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6px solid #4FD1C1;
  border-radius: 24px;
  background-size:cover;
}
.slider-title {

}
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  background-color: #4FD1C1;
}
.carousel__pagination-button {
    margin: 5px;
    width: 10px;
    height: 5px;
    border: 0;
    cursor: pointer;
    background-color: #74d3b3;
}
.carousel__pagination-button--active {
  background-color: #4FD1C1;
}
@media(max-width: 700px) {
  .carousel__item {
    min-height: 350px;
  } 
}
@media(max-width: 500px) {
  .carousel__item {
    min-height: 250px;
  }
  .carousel__next {
    right:10px;
  }
  .carousel__prev {
    left:10px;
  }
}
</style>