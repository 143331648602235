<template>

  <section class="container">
    <Slider v-if="this.listings.length > 0" v-bind:listings="listings"/>  

    <Slider v-else v-bind:listings="[{
      id: 1,
      title: 'No Active Competitions',
      featureImage: '',
    }]"/>  
  </section>

  <!-- Active Competitions -->

  <section class="container">
    <h1 v-if="this.listings.length > 0" class="text-center mt-5 mb-5">Active Competitions</h1>

    <div class="row">
      <CompetitionListing v-for="listing in listings" v-bind:listing="listing" v-bind:key="listing.id" v-bind:account="account"/>
    </div>
    
    <!-- <button type="button" class="btn btn-custom w-30">Show All</button> -->
  </section>

  <!-- How to enter -->
  <section class="container">
    <div class="container" id="howto">

      <h1 class="title py-4">How to enter</h1>
      <p class="sub-heading">Three simple steps</p>

      <div class="row">
        <div class="col-md-4 col-xs-12">
          <span class="d-block p-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="237" viewBox="0 0 237 237">
              <g id="Ellipse_744" data-name="Ellipse 744" fill="#fff" stroke="#fff" stroke-width="1">
                <circle cx="118.5" cy="118.5" r="118.5" stroke="none"/>
                <circle cx="118.5" cy="118.5" r="118" fill="none"/>
              </g>
              <g id="mail-send" transform="translate(74.274 13.228)">
                <path id="Path_33" data-name="Path 33" d="M114.543,125.052c.03-.006.058-.014.087-.021s.05-.012.074-.019.056-.019.084-.029.049-.017.074-.027.051-.024.077-.036.05-.023.074-.036.047-.028.071-.042.049-.028.072-.044.049-.035.073-.054.041-.03.061-.046c.037-.03.072-.062.106-.1l.019-.017.018-.02c.033-.034.065-.068.094-.1s.033-.044.049-.066.035-.045.05-.068.032-.051.047-.077.027-.043.039-.066.026-.053.038-.08.023-.047.033-.071.02-.053.029-.079.019-.052.027-.078.014-.054.021-.081.014-.053.02-.08.01-.064.015-.1.007-.045.01-.068a1.7,1.7,0,0,0,.009-.17V86.964a1.7,1.7,0,0,0-1.7-1.7H60.544l-.045,0c-.032,0-.065,0-.1.005l-.077.008-.087.013-.08.017-.081.021L60,85.351l-.079.029-.073.031-.08.038-.066.036-.078.047-.062.042c-.024.017-.048.035-.071.053l-.063.052c-.02.018-.041.036-.06.055s-.044.044-.065.067-.022.021-.032.033l-.017.021c-.021.025-.041.051-.061.077s-.03.038-.043.058-.034.052-.05.078-.027.043-.04.065-.027.052-.04.078-.024.048-.035.072-.021.052-.031.078-.02.051-.028.077-.015.053-.022.079-.015.054-.021.082-.01.052-.014.078-.01.059-.014.089,0,.05-.006.075,0,.064,0,.1c0,.009,0,.018,0,.028V97.47a1.7,1.7,0,1,0,3.407,0V90.747l15.147,13.411a1.721,1.721,0,0,0-.226.188L62.247,119.268v-11.2a1.7,1.7,0,1,0-3.407,0v2.082h-8.8a1.7,1.7,0,1,0,0,3.407h8.8v9.821a1.7,1.7,0,0,0,1.7,1.7H114.21c.056,0,.112,0,.168-.008.026,0,.051-.007.077-.011S114.514,125.058,114.543,125.052Zm-2.036-5.784L97.585,104.346l-.03-.027L108.773,94.8a1.7,1.7,0,0,0-2.2-2.6L87.4,108.467l-22.363-19.8h47.469ZM79.578,106.756a1.7,1.7,0,0,0,.3-.4L86.247,112a1.7,1.7,0,0,0,2.232.024l6.494-5.51a1.712,1.712,0,0,0,.2.245L110.1,121.678H64.657Z" transform="translate(-37.354 0)" fill="#34c2b0"/>
                <path id="Path_34" data-name="Path 34" d="M1.7,159.083H30.478a1.7,1.7,0,0,0,0-3.407H1.7a1.7,1.7,0,0,0,0,3.407Z" transform="translate(0 -54.42)" fill="#34c2b0"/>
                <path id="Path_35" data-name="Path 35" d="M14.829,117.752h12.92a1.7,1.7,0,1,0,0-3.407H14.829a1.7,1.7,0,1,0,0,3.407Z" transform="translate(-10.143 -22.478)" fill="#34c2b0"/>
                <path id="Path_36" data-name="Path 36" d="M14.2,226.51H1.7a1.7,1.7,0,0,0,0,3.407H14.2a1.7,1.7,0,0,0,0-3.407Z" transform="translate(0 -109.163)" fill="#34c2b0"/>
              </g>
            </svg>
          </span>
          <span class="d-block p-2">Sign Up</span>
        </div>
        <div class="col-md-4 col-xs-12">
          <span class="d-block p-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="237" viewBox="0 0 237 237">
              <g id="Ellipse_227" data-name="Ellipse 227" fill="#fff" stroke="#fff" stroke-width="1">
                <circle cx="118.5" cy="118.5" r="118.5" stroke="none"/>
                <circle cx="118.5" cy="118.5" r="118" fill="none"/>
              </g>
              <g id="wallet" transform="translate(51.897 82.156)">
                <path id="Path_17" data-name="Path 17" d="M90.556,278.3V254.842h30.48a1.334,1.334,0,0,0,0-2.669H89.221a1.334,1.334,0,0,0-1.334,1.334v26.13a1.334,1.334,0,0,0,1.334,1.334h31.814a1.334,1.334,0,1,0,0-2.669Z" transform="translate(-38.129 -218.524)" fill="#34c2b0"/>
                <path id="Path_18" data-name="Path 18" d="M99.16,41.287H97.674V29.112a1.334,1.334,0,0,0-1.334-1.334H95.146l3.536-7.456a3.646,3.646,0,0,0-1.693-4.833L78.512,5.464l-.065-.033a3.635,3.635,0,0,0-1.772-.343V3.641A3.645,3.645,0,0,0,73.034,0H49.6a3.645,3.645,0,0,0-3.641,3.641V28.256a3.96,3.96,0,0,0-2.072,3.479V64.363a3.961,3.961,0,0,0,3.957,3.957h48.5a1.334,1.334,0,0,0,1.334-1.334V54.809H99.16a1.334,1.334,0,0,0,1.334-1.334V42.622A1.335,1.335,0,0,0,99.16,41.287ZM77.278,7.831,95.744,17.85l.065.033a.973.973,0,0,1,.462,1.3l-4.078,8.6H76.675V7.773a.974.974,0,0,1,.6.058Zm-28.65-4.19a.973.973,0,0,1,.972-.972H73.034a.973.973,0,0,1,.972.972V27.778H70.8V10.82A1.334,1.334,0,0,0,69.47,9.485a2.282,2.282,0,0,1-2.28-2.28,1.334,1.334,0,0,0-1.334-1.334H56.985A1.334,1.334,0,0,0,55.65,7.206a2.282,2.282,0,0,1-2.28,2.28c-.044,0-.088,0-.132,0a1.334,1.334,0,0,0-1.408,1.332V27.778h-3.2ZM68.135,27.778H54.5V12.024A4.967,4.967,0,0,0,58.136,8.54H64.7a4.968,4.968,0,0,0,3.431,3.431ZM95.005,65.65H47.844a1.289,1.289,0,0,1-1.288-1.288V31.734a1.29,1.29,0,0,1,1.189-1.284h0l.029,0H95.005V41.288h-12.1a1.334,1.334,0,0,0-1.334,1.334V53.475a1.334,1.334,0,0,0,1.334,1.334h12.1Zm2.82-13.51H84.241V43.956H97.825v8.185Z" fill="#34c2b0"/>
                <path id="Path_19" data-name="Path 19" d="M371.355,353.016h2.168a1.334,1.334,0,1,0,0-2.669h-2.168a1.334,1.334,0,1,0,0,2.669Z" transform="translate(-282.616 -303.598)" fill="#34c2b0"/>
              </g>
            </svg>
          </span>
          <span class="d-block p-2">Top Up</span>
        </div>
        <div class="col-md-4 col-xs-12">
        <span class="d-block p-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="237" viewBox="0 0 237 237">
            <g id="Ellipse_743" data-name="Ellipse 743" fill="#fff" stroke="#fff" stroke-width="1">
              <circle cx="118.5" cy="118.5" r="118.5" stroke="none"/>
              <circle cx="118.5" cy="118.5" r="118" fill="none"/>
            </g>
            <g id="happiness" transform="translate(81.793 76.793)">
              <g id="Group_4" data-name="Group 4" transform="translate(47.422 25.379)">
                <g id="Group_3" data-name="Group 3">
                  <path id="Path_20" data-name="Path 20" d="M295.729,157.182a1.618,1.618,0,0,0-2.256.383l-.031.044a1.618,1.618,0,1,0,2.639,1.872l.031-.044A1.618,1.618,0,0,0,295.729,157.182Z" transform="translate(-293.143 -156.884)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_6" data-name="Group 6" transform="translate(35.254 29.671)">
                <g id="Group_5" data-name="Group 5">
                  <path id="Path_21" data-name="Path 21" d="M228.993,184a1.617,1.617,0,0,0-2.277-.225,20.049,20.049,0,0,1-7.591,3.9,1.618,1.618,0,1,0,.835,3.126,23.264,23.264,0,0,0,8.808-4.525A1.617,1.617,0,0,0,228.993,184Z" transform="translate(-217.925 -183.411)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_8" data-name="Group 8" transform="translate(0 8.736)">
                <g id="Group_7" data-name="Group 7">
                  <path id="Path_22" data-name="Path 22" d="M77.974,81.9a4.859,4.859,0,0,0-4.853,4.853v.809A13.77,13.77,0,0,1,61.71,101.11a7.754,7.754,0,0,0,1.339-4.365V95.621a7.8,7.8,0,0,0-15.6,0v1.124a7.751,7.751,0,0,0,1.277,4.269,13.773,13.773,0,0,1-10.9-13.451v-.809a4.853,4.853,0,0,0-9.706,0v.809a23.264,23.264,0,0,0,1.957,9.393H19.077V80.664A1.617,1.617,0,0,0,17.9,79.108,20.315,20.315,0,0,1,3.235,59.662v-.809a1.618,1.618,0,0,1,3.235,0v.809A17.005,17.005,0,0,0,23.457,76.648h7.786A17.005,17.005,0,0,0,48.228,59.662v-.809a1.618,1.618,0,0,1,3.235,0v.809a20.08,20.08,0,0,1-1.332,7.219,1.618,1.618,0,0,0,3.021,1.159A23.3,23.3,0,0,0,54.7,59.662v-.809a4.853,4.853,0,0,0-9.706,0v.809a13.77,13.77,0,0,1-11.41,13.547,7.753,7.753,0,0,0,1.339-4.365V67.721a7.8,7.8,0,1,0-15.6,0v1.124A7.75,7.75,0,0,0,20.6,73.113a13.773,13.773,0,0,1-10.9-13.451v-.809a4.853,4.853,0,0,0-9.706,0v.809A23.223,23.223,0,0,0,4.829,73.9a23.7,23.7,0,0,0,11.013,7.946V98.573a1.618,1.618,0,0,0,1.618,1.618h.3v26.283a1.618,1.618,0,1,0,3.235,0V100.191H31.823q.53.825,1.134,1.613c.107.14.219.276.329.413v24.257a1.618,1.618,0,1,0,3.235,0V105.523a23.647,23.647,0,0,0,7.449,4.227v16.724a1.618,1.618,0,1,0,3.235,0V108.564a1.617,1.617,0,0,0-1.173-1.555A20.315,20.315,0,0,1,31.363,87.563v-.809a1.618,1.618,0,0,1,3.235,0v.809a17.005,17.005,0,0,0,16.986,16.986H59.37A17.005,17.005,0,0,0,76.356,87.563v-.809a1.618,1.618,0,0,1,3.235,0v.809A20.252,20.252,0,0,1,64.583,107.1a1.618,1.618,0,0,0-1.2,1.563V120.38a1.618,1.618,0,0,0,3.235,0V109.874A23.493,23.493,0,0,0,82.827,87.563v-.809A4.859,4.859,0,0,0,77.974,81.9ZM22.561,67.72a4.563,4.563,0,1,1,9.125,0v1.124a4.563,4.563,0,0,1-9.125,0ZM59.814,96.745a4.563,4.563,0,0,1-9.125,0V95.621a4.563,4.563,0,0,1,9.125,0Z" transform="translate(0 -54)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_10" data-name="Group 10" transform="translate(63.382 79.591)">
                <g id="Group_9" data-name="Group 9">
                  <path id="Path_23" data-name="Path 23" d="M394.561,492.474a1.617,1.617,0,1,0,.474,1.144A1.629,1.629,0,0,0,394.561,492.474Z" transform="translate(-391.8 -492)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_12" data-name="Group 12" transform="translate(25.506 60.502)">
                <g id="Group_11" data-name="Group 11">
                  <path id="Path_24" data-name="Path 24" d="M159.285,374a1.618,1.618,0,0,0-1.618,1.618v19.089a1.618,1.618,0,0,0,3.235,0V375.618A1.618,1.618,0,0,0,159.285,374Z" transform="translate(-157.667 -374)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_14" data-name="Group 14" transform="translate(73.768 13.265)">
                <g id="Group_13" data-name="Group 13">
                  <path id="Path_25" data-name="Path 25" d="M457.618,82A1.618,1.618,0,0,0,456,83.618v2.011a1.618,1.618,0,1,0,3.235,0V83.618A1.618,1.618,0,0,0,457.618,82Z" transform="translate(-456 -82)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_16" data-name="Group 16" transform="translate(73.768 22.902)">
                <g id="Group_15" data-name="Group 15">
                  <path id="Path_26" data-name="Path 26" d="M457.618,141.57A1.618,1.618,0,0,0,456,143.188V145.2a1.618,1.618,0,1,0,3.235,0v-2.011A1.618,1.618,0,0,0,457.618,141.57Z" transform="translate(-456 -141.57)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_18" data-name="Group 18" transform="translate(77.581 19.089)">
                <g id="Group_17" data-name="Group 17">
                  <path id="Path_27" data-name="Path 27" d="M483.2,118h-2.011a1.618,1.618,0,0,0,0,3.235H483.2a1.618,1.618,0,0,0,0-3.235Z" transform="translate(-479.57 -118)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_20" data-name="Group 20" transform="translate(67.944 19.089)">
                <g id="Group_19" data-name="Group 19">
                  <path id="Path_28" data-name="Path 28" d="M423.629,118h-2.011a1.618,1.618,0,1,0,0,3.235h2.011a1.618,1.618,0,1,0,0-3.235Z" transform="translate(-420 -118)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_22" data-name="Group 22" transform="translate(33.951)">
                <g id="Group_21" data-name="Group 21">
                  <path id="Path_29" data-name="Path 29" d="M211.491,0a1.618,1.618,0,0,0-1.618,1.618V3.629a1.618,1.618,0,0,0,3.235,0V1.618A1.618,1.618,0,0,0,211.491,0Z" transform="translate(-209.873)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_24" data-name="Group 24" transform="translate(33.951 9.637)">
                <g id="Group_23" data-name="Group 23">
                  <path id="Path_30" data-name="Path 30" d="M211.491,59.57a1.618,1.618,0,0,0-1.618,1.618V63.2a1.618,1.618,0,0,0,3.235,0V61.188A1.618,1.618,0,0,0,211.491,59.57Z" transform="translate(-209.873 -59.57)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_26" data-name="Group 26" transform="translate(37.764 5.824)">
                <g id="Group_25" data-name="Group 25">
                  <path id="Path_31" data-name="Path 31" d="M237.072,36h-2.011a1.618,1.618,0,0,0,0,3.235h2.011a1.618,1.618,0,0,0,0-3.235Z" transform="translate(-233.443 -36)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_28" data-name="Group 28" transform="translate(28.128 5.824)">
                <g id="Group_27" data-name="Group 27">
                  <path id="Path_32" data-name="Path 32" d="M177.5,36h-2.011a1.618,1.618,0,0,0,0,3.235H177.5a1.618,1.618,0,0,0,0-3.235Z" transform="translate(-173.873 -36)" fill="#34c2b0"/>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span class="d-block p-2">Play</span>
        </div>
      </div>



      <!-- <ul class="list-inline">
        <li class="list-inline-item">
          <span class="d-block p-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="237" viewBox="0 0 237 237">
              <g id="Ellipse_744" data-name="Ellipse 744" fill="#fff" stroke="#fff" stroke-width="1">
                <circle cx="118.5" cy="118.5" r="118.5" stroke="none"/>
                <circle cx="118.5" cy="118.5" r="118" fill="none"/>
              </g>
              <g id="mail-send" transform="translate(74.274 13.228)">
                <path id="Path_33" data-name="Path 33" d="M114.543,125.052c.03-.006.058-.014.087-.021s.05-.012.074-.019.056-.019.084-.029.049-.017.074-.027.051-.024.077-.036.05-.023.074-.036.047-.028.071-.042.049-.028.072-.044.049-.035.073-.054.041-.03.061-.046c.037-.03.072-.062.106-.1l.019-.017.018-.02c.033-.034.065-.068.094-.1s.033-.044.049-.066.035-.045.05-.068.032-.051.047-.077.027-.043.039-.066.026-.053.038-.08.023-.047.033-.071.02-.053.029-.079.019-.052.027-.078.014-.054.021-.081.014-.053.02-.08.01-.064.015-.1.007-.045.01-.068a1.7,1.7,0,0,0,.009-.17V86.964a1.7,1.7,0,0,0-1.7-1.7H60.544l-.045,0c-.032,0-.065,0-.1.005l-.077.008-.087.013-.08.017-.081.021L60,85.351l-.079.029-.073.031-.08.038-.066.036-.078.047-.062.042c-.024.017-.048.035-.071.053l-.063.052c-.02.018-.041.036-.06.055s-.044.044-.065.067-.022.021-.032.033l-.017.021c-.021.025-.041.051-.061.077s-.03.038-.043.058-.034.052-.05.078-.027.043-.04.065-.027.052-.04.078-.024.048-.035.072-.021.052-.031.078-.02.051-.028.077-.015.053-.022.079-.015.054-.021.082-.01.052-.014.078-.01.059-.014.089,0,.05-.006.075,0,.064,0,.1c0,.009,0,.018,0,.028V97.47a1.7,1.7,0,1,0,3.407,0V90.747l15.147,13.411a1.721,1.721,0,0,0-.226.188L62.247,119.268v-11.2a1.7,1.7,0,1,0-3.407,0v2.082h-8.8a1.7,1.7,0,1,0,0,3.407h8.8v9.821a1.7,1.7,0,0,0,1.7,1.7H114.21c.056,0,.112,0,.168-.008.026,0,.051-.007.077-.011S114.514,125.058,114.543,125.052Zm-2.036-5.784L97.585,104.346l-.03-.027L108.773,94.8a1.7,1.7,0,0,0-2.2-2.6L87.4,108.467l-22.363-19.8h47.469ZM79.578,106.756a1.7,1.7,0,0,0,.3-.4L86.247,112a1.7,1.7,0,0,0,2.232.024l6.494-5.51a1.712,1.712,0,0,0,.2.245L110.1,121.678H64.657Z" transform="translate(-37.354 0)" fill="#34c2b0"/>
                <path id="Path_34" data-name="Path 34" d="M1.7,159.083H30.478a1.7,1.7,0,0,0,0-3.407H1.7a1.7,1.7,0,0,0,0,3.407Z" transform="translate(0 -54.42)" fill="#34c2b0"/>
                <path id="Path_35" data-name="Path 35" d="M14.829,117.752h12.92a1.7,1.7,0,1,0,0-3.407H14.829a1.7,1.7,0,1,0,0,3.407Z" transform="translate(-10.143 -22.478)" fill="#34c2b0"/>
                <path id="Path_36" data-name="Path 36" d="M14.2,226.51H1.7a1.7,1.7,0,0,0,0,3.407H14.2a1.7,1.7,0,0,0,0-3.407Z" transform="translate(0 -109.163)" fill="#34c2b0"/>
              </g>
            </svg>
          </span>
          <span class="d-block p-2">Sign Up</span>

        </li>
        <li class="list-inline-item">
          <span class="d-block p-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="237" viewBox="0 0 237 237">
              <g id="Ellipse_227" data-name="Ellipse 227" fill="#fff" stroke="#fff" stroke-width="1">
                <circle cx="118.5" cy="118.5" r="118.5" stroke="none"/>
                <circle cx="118.5" cy="118.5" r="118" fill="none"/>
              </g>
              <g id="wallet" transform="translate(51.897 82.156)">
                <path id="Path_17" data-name="Path 17" d="M90.556,278.3V254.842h30.48a1.334,1.334,0,0,0,0-2.669H89.221a1.334,1.334,0,0,0-1.334,1.334v26.13a1.334,1.334,0,0,0,1.334,1.334h31.814a1.334,1.334,0,1,0,0-2.669Z" transform="translate(-38.129 -218.524)" fill="#34c2b0"/>
                <path id="Path_18" data-name="Path 18" d="M99.16,41.287H97.674V29.112a1.334,1.334,0,0,0-1.334-1.334H95.146l3.536-7.456a3.646,3.646,0,0,0-1.693-4.833L78.512,5.464l-.065-.033a3.635,3.635,0,0,0-1.772-.343V3.641A3.645,3.645,0,0,0,73.034,0H49.6a3.645,3.645,0,0,0-3.641,3.641V28.256a3.96,3.96,0,0,0-2.072,3.479V64.363a3.961,3.961,0,0,0,3.957,3.957h48.5a1.334,1.334,0,0,0,1.334-1.334V54.809H99.16a1.334,1.334,0,0,0,1.334-1.334V42.622A1.335,1.335,0,0,0,99.16,41.287ZM77.278,7.831,95.744,17.85l.065.033a.973.973,0,0,1,.462,1.3l-4.078,8.6H76.675V7.773a.974.974,0,0,1,.6.058Zm-28.65-4.19a.973.973,0,0,1,.972-.972H73.034a.973.973,0,0,1,.972.972V27.778H70.8V10.82A1.334,1.334,0,0,0,69.47,9.485a2.282,2.282,0,0,1-2.28-2.28,1.334,1.334,0,0,0-1.334-1.334H56.985A1.334,1.334,0,0,0,55.65,7.206a2.282,2.282,0,0,1-2.28,2.28c-.044,0-.088,0-.132,0a1.334,1.334,0,0,0-1.408,1.332V27.778h-3.2ZM68.135,27.778H54.5V12.024A4.967,4.967,0,0,0,58.136,8.54H64.7a4.968,4.968,0,0,0,3.431,3.431ZM95.005,65.65H47.844a1.289,1.289,0,0,1-1.288-1.288V31.734a1.29,1.29,0,0,1,1.189-1.284h0l.029,0H95.005V41.288h-12.1a1.334,1.334,0,0,0-1.334,1.334V53.475a1.334,1.334,0,0,0,1.334,1.334h12.1Zm2.82-13.51H84.241V43.956H97.825v8.185Z" fill="#34c2b0"/>
                <path id="Path_19" data-name="Path 19" d="M371.355,353.016h2.168a1.334,1.334,0,1,0,0-2.669h-2.168a1.334,1.334,0,1,0,0,2.669Z" transform="translate(-282.616 -303.598)" fill="#34c2b0"/>
              </g>
            </svg>
          </span>
          <span class="d-block p-2">Top Up</span>
        </li>
        <li class="list-inline-item">
        <span class="d-block p-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="237" viewBox="0 0 237 237">
            <g id="Ellipse_743" data-name="Ellipse 743" fill="#fff" stroke="#fff" stroke-width="1">
              <circle cx="118.5" cy="118.5" r="118.5" stroke="none"/>
              <circle cx="118.5" cy="118.5" r="118" fill="none"/>
            </g>
            <g id="happiness" transform="translate(81.793 76.793)">
              <g id="Group_4" data-name="Group 4" transform="translate(47.422 25.379)">
                <g id="Group_3" data-name="Group 3">
                  <path id="Path_20" data-name="Path 20" d="M295.729,157.182a1.618,1.618,0,0,0-2.256.383l-.031.044a1.618,1.618,0,1,0,2.639,1.872l.031-.044A1.618,1.618,0,0,0,295.729,157.182Z" transform="translate(-293.143 -156.884)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_6" data-name="Group 6" transform="translate(35.254 29.671)">
                <g id="Group_5" data-name="Group 5">
                  <path id="Path_21" data-name="Path 21" d="M228.993,184a1.617,1.617,0,0,0-2.277-.225,20.049,20.049,0,0,1-7.591,3.9,1.618,1.618,0,1,0,.835,3.126,23.264,23.264,0,0,0,8.808-4.525A1.617,1.617,0,0,0,228.993,184Z" transform="translate(-217.925 -183.411)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_8" data-name="Group 8" transform="translate(0 8.736)">
                <g id="Group_7" data-name="Group 7">
                  <path id="Path_22" data-name="Path 22" d="M77.974,81.9a4.859,4.859,0,0,0-4.853,4.853v.809A13.77,13.77,0,0,1,61.71,101.11a7.754,7.754,0,0,0,1.339-4.365V95.621a7.8,7.8,0,0,0-15.6,0v1.124a7.751,7.751,0,0,0,1.277,4.269,13.773,13.773,0,0,1-10.9-13.451v-.809a4.853,4.853,0,0,0-9.706,0v.809a23.264,23.264,0,0,0,1.957,9.393H19.077V80.664A1.617,1.617,0,0,0,17.9,79.108,20.315,20.315,0,0,1,3.235,59.662v-.809a1.618,1.618,0,0,1,3.235,0v.809A17.005,17.005,0,0,0,23.457,76.648h7.786A17.005,17.005,0,0,0,48.228,59.662v-.809a1.618,1.618,0,0,1,3.235,0v.809a20.08,20.08,0,0,1-1.332,7.219,1.618,1.618,0,0,0,3.021,1.159A23.3,23.3,0,0,0,54.7,59.662v-.809a4.853,4.853,0,0,0-9.706,0v.809a13.77,13.77,0,0,1-11.41,13.547,7.753,7.753,0,0,0,1.339-4.365V67.721a7.8,7.8,0,1,0-15.6,0v1.124A7.75,7.75,0,0,0,20.6,73.113a13.773,13.773,0,0,1-10.9-13.451v-.809a4.853,4.853,0,0,0-9.706,0v.809A23.223,23.223,0,0,0,4.829,73.9a23.7,23.7,0,0,0,11.013,7.946V98.573a1.618,1.618,0,0,0,1.618,1.618h.3v26.283a1.618,1.618,0,1,0,3.235,0V100.191H31.823q.53.825,1.134,1.613c.107.14.219.276.329.413v24.257a1.618,1.618,0,1,0,3.235,0V105.523a23.647,23.647,0,0,0,7.449,4.227v16.724a1.618,1.618,0,1,0,3.235,0V108.564a1.617,1.617,0,0,0-1.173-1.555A20.315,20.315,0,0,1,31.363,87.563v-.809a1.618,1.618,0,0,1,3.235,0v.809a17.005,17.005,0,0,0,16.986,16.986H59.37A17.005,17.005,0,0,0,76.356,87.563v-.809a1.618,1.618,0,0,1,3.235,0v.809A20.252,20.252,0,0,1,64.583,107.1a1.618,1.618,0,0,0-1.2,1.563V120.38a1.618,1.618,0,0,0,3.235,0V109.874A23.493,23.493,0,0,0,82.827,87.563v-.809A4.859,4.859,0,0,0,77.974,81.9ZM22.561,67.72a4.563,4.563,0,1,1,9.125,0v1.124a4.563,4.563,0,0,1-9.125,0ZM59.814,96.745a4.563,4.563,0,0,1-9.125,0V95.621a4.563,4.563,0,0,1,9.125,0Z" transform="translate(0 -54)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_10" data-name="Group 10" transform="translate(63.382 79.591)">
                <g id="Group_9" data-name="Group 9">
                  <path id="Path_23" data-name="Path 23" d="M394.561,492.474a1.617,1.617,0,1,0,.474,1.144A1.629,1.629,0,0,0,394.561,492.474Z" transform="translate(-391.8 -492)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_12" data-name="Group 12" transform="translate(25.506 60.502)">
                <g id="Group_11" data-name="Group 11">
                  <path id="Path_24" data-name="Path 24" d="M159.285,374a1.618,1.618,0,0,0-1.618,1.618v19.089a1.618,1.618,0,0,0,3.235,0V375.618A1.618,1.618,0,0,0,159.285,374Z" transform="translate(-157.667 -374)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_14" data-name="Group 14" transform="translate(73.768 13.265)">
                <g id="Group_13" data-name="Group 13">
                  <path id="Path_25" data-name="Path 25" d="M457.618,82A1.618,1.618,0,0,0,456,83.618v2.011a1.618,1.618,0,1,0,3.235,0V83.618A1.618,1.618,0,0,0,457.618,82Z" transform="translate(-456 -82)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_16" data-name="Group 16" transform="translate(73.768 22.902)">
                <g id="Group_15" data-name="Group 15">
                  <path id="Path_26" data-name="Path 26" d="M457.618,141.57A1.618,1.618,0,0,0,456,143.188V145.2a1.618,1.618,0,1,0,3.235,0v-2.011A1.618,1.618,0,0,0,457.618,141.57Z" transform="translate(-456 -141.57)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_18" data-name="Group 18" transform="translate(77.581 19.089)">
                <g id="Group_17" data-name="Group 17">
                  <path id="Path_27" data-name="Path 27" d="M483.2,118h-2.011a1.618,1.618,0,0,0,0,3.235H483.2a1.618,1.618,0,0,0,0-3.235Z" transform="translate(-479.57 -118)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_20" data-name="Group 20" transform="translate(67.944 19.089)">
                <g id="Group_19" data-name="Group 19">
                  <path id="Path_28" data-name="Path 28" d="M423.629,118h-2.011a1.618,1.618,0,1,0,0,3.235h2.011a1.618,1.618,0,1,0,0-3.235Z" transform="translate(-420 -118)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_22" data-name="Group 22" transform="translate(33.951)">
                <g id="Group_21" data-name="Group 21">
                  <path id="Path_29" data-name="Path 29" d="M211.491,0a1.618,1.618,0,0,0-1.618,1.618V3.629a1.618,1.618,0,0,0,3.235,0V1.618A1.618,1.618,0,0,0,211.491,0Z" transform="translate(-209.873)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_24" data-name="Group 24" transform="translate(33.951 9.637)">
                <g id="Group_23" data-name="Group 23">
                  <path id="Path_30" data-name="Path 30" d="M211.491,59.57a1.618,1.618,0,0,0-1.618,1.618V63.2a1.618,1.618,0,0,0,3.235,0V61.188A1.618,1.618,0,0,0,211.491,59.57Z" transform="translate(-209.873 -59.57)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_26" data-name="Group 26" transform="translate(37.764 5.824)">
                <g id="Group_25" data-name="Group 25">
                  <path id="Path_31" data-name="Path 31" d="M237.072,36h-2.011a1.618,1.618,0,0,0,0,3.235h2.011a1.618,1.618,0,0,0,0-3.235Z" transform="translate(-233.443 -36)" fill="#34c2b0"/>
                </g>
              </g>
              <g id="Group_28" data-name="Group 28" transform="translate(28.128 5.824)">
                <g id="Group_27" data-name="Group 27">
                  <path id="Path_32" data-name="Path 32" d="M177.5,36h-2.011a1.618,1.618,0,0,0,0,3.235H177.5a1.618,1.618,0,0,0,0-3.235Z" transform="translate(-173.873 -36)" fill="#34c2b0"/>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span class="d-block p-2">Play</span>

        </li>
      </ul> -->
      <router-link v-bind:to="'terms-and-conditions#howtoplay'" class="btn btn-custom mb-5 mt-2" style="margin-top:30px;background-color: #fff; color: #222222; color:#34C2B0"> 
        HOW TO PLAY
      </router-link> 

    </div>
  </section>

  <Footer />

</template>

<script>
import Api from '../scripts/api'

import Footer from '../components/global/Footer'
import CompetitionListing from '../components/home/CompetitionListing'
import Slider from '../components/global/Slider'


export default {
    components: {
      Slider,
      CompetitionListing,
      Footer,
    },
    props: {
      account: Object
    },
    data() {
      return {
        listings: [],
      }
    },
    async created() {
      var result = await Api.competition.listActive();
      if(result.status === 200) {
        this.listings = await result.json();
        this.listings = this.listings.slice(0, 9); // limit to 9 active competitions.
      } 
      else {
        this.$toast.error('Could not retrieve listings.')
      }
    }
}
</script>
