<template>
  <footer class="container">
    <div class="row">

      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
        <h2>Help</h2>

        <ul class="list-inline">
          <li class="list-inline-item">
            <a href="mailto:info@competitionmania.co.uk">info@competitionmania.co.uk</a>
          </li>
        </ul>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
        <h2>Stay Connected</h2> 
          <ul>
            <li>
              <a href="https://www.facebook.com/CompetitionManiaLtd" target="_blank">
                Facebook

                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                  <path id="Icon_awesome-facebook" data-name="Icon awesome-facebook" d="M23.563,12.132a11.5,11.5,0,1,0-13.3,11.43V15.477H7.344V12.132h2.921V9.583c0-2.9,1.716-4.5,4.344-4.5a17.594,17.594,0,0,1,2.575.226V8.154h-1.45a1.667,1.667,0,0,0-1.874,1.807v2.172h3.189l-.51,3.345H13.859v8.085A11.553,11.553,0,0,0,23.563,12.132Z" transform="translate(-0.563 -0.563)"/>
                </svg>


              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/competitionmanialtd/" target="_blank">
                Instagram
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                  <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M11.5,7.841a5.9,5.9,0,1,0,5.9,5.9A5.887,5.887,0,0,0,11.5,7.841Zm0,9.731a3.834,3.834,0,1,1,3.833-3.834A3.84,3.84,0,0,1,11.5,17.571ZM19.009,7.6a1.375,1.375,0,1,1-1.375-1.375A1.372,1.372,0,0,1,19.009,7.6Zm3.9,1.4a6.808,6.808,0,0,0-1.857-4.819,6.849,6.849,0,0,0-4.818-1.858c-1.9-.108-7.589-.108-9.487,0A6.839,6.839,0,0,0,1.933,4.171,6.83,6.83,0,0,0,.076,8.99c-.108,1.9-.108,7.591,0,9.489A6.807,6.807,0,0,0,1.933,23.3a6.858,6.858,0,0,0,4.818,1.858c1.9.108,7.589.108,9.487,0A6.8,6.8,0,0,0,21.056,23.3a6.852,6.852,0,0,0,1.857-4.819c.108-1.9.108-7.585,0-9.484ZM20.461,20.517A3.881,3.881,0,0,1,18.275,22.7c-1.514.6-5.105.462-6.778.462s-5.27.133-6.778-.462a3.881,3.881,0,0,1-2.186-2.186c-.6-1.514-.462-5.107-.462-6.78s-.133-5.271.462-6.78A3.881,3.881,0,0,1,4.719,4.772c1.514-.6,5.105-.462,6.778-.462s5.27-.133,6.778.462a3.881,3.881,0,0,1,2.186,2.186c.6,1.514.462,5.107.462,6.78S21.062,19.008,20.461,20.517Z" transform="translate(0.005 -2.238)"/>
                </svg>

              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@competitionmania" target="_blank">
                TikTok
                <svg xmlns="http://www.w3.org/2000/svg" width="18.791" height="21.629" viewBox="0 0 18.791 21.629">
                  <path id="Icon_simple-tiktok" data-name="Icon simple-tiktok" d="M12.238.018C13.418,0,14.59.009,15.761,0a5.613,5.613,0,0,0,1.577,3.758A6.356,6.356,0,0,0,21.16,5.371V9a9.646,9.646,0,0,1-3.785-.874,11.135,11.135,0,0,1-1.46-.838c-.009,2.632.009,5.263-.018,7.886a6.883,6.883,0,0,1-1.217,3.551A6.714,6.714,0,0,1,9.354,21.62a6.571,6.571,0,0,1-3.677-.928,6.8,6.8,0,0,1-3.289-5.146c-.018-.451-.027-.9-.009-1.343a6.785,6.785,0,0,1,7.868-6.02c.018,1.334-.036,2.668-.036,4a3.092,3.092,0,0,0-3.947,1.911,3.575,3.575,0,0,0-.126,1.451,3.067,3.067,0,0,0,3.154,2.586,3.028,3.028,0,0,0,2.5-1.451,2.079,2.079,0,0,0,.369-.955c.09-1.613.054-3.217.063-4.83.009-3.632-.009-7.255.018-10.878Z" transform="translate(-2.369)"/>
                </svg>
              </a>
            </li>
          </ul>

          <!-- <a href="mailto:info@competitionmania.co.uk">Instagram</a> -->
      </div>
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
        <h2>Legal</h2>
        <ul>
          <li><router-link v-bind:to="'/terms-and-conditions'">Terms & Conditions</router-link></li>
          <li><router-link v-bind:to="'/terms-of-use'">Website Terms Of Use</router-link></li>
          <li><router-link v-bind:to="'/acceptable-use-policy'">Acceptable Use Policy</router-link></li>
          <li><router-link v-bind:to="'/privacy-policy'">Privacy Policy</router-link></li>
          <li><router-link v-bind:to="'/faq'">FAQs</router-link></li>
        </ul>
      </div>



      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
        <h2>Payment Methods</h2>
        <div class="row mt-5">
          <div class="col">
            <div class="card h-100 justify-content-center text-start">
                    <!-- VISA -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="74.121" height="22.471" viewBox="0 0 74.121 22.471">
                      <g id="visa" transform="translate(0 -175.6)">
                        <path id="Path_84" data-name="Path 84" d="M184.8,201.577l3.765-21.177h5.883L190.8,201.577Z" transform="translate(-157.622 -4.094)" fill="#3c58bf"/>
                        <path id="Path_85" data-name="Path 85" d="M184.8,201.577l4.824-21.177h4.824L190.8,201.577Z" transform="translate(-157.622 -4.094)" fill="#293688"/>
                        <path id="Path_86" data-name="Path 86" d="M266,176.541a14.923,14.923,0,0,0-5.412-.941c-5.883,0-10.118,2.941-10.118,7.177,0,3.177,2.941,4.824,5.294,5.883s3.059,1.765,3.059,2.706c0,1.412-1.882,2.118-3.53,2.118a12.788,12.788,0,0,1-5.647-1.177l-.824-.353L248,196.777a19.076,19.076,0,0,0,6.706,1.177c6.236,0,10.353-2.941,10.353-7.412,0-2.471-1.529-4.353-5.059-5.883-2.118-1.059-3.412-1.647-3.412-2.706,0-.941,1.059-1.882,3.412-1.882a10.791,10.791,0,0,1,4.471.824l.588.235.941-4.588Z" transform="translate(-211.528)" fill="#3c58bf"/>
                        <path id="Path_87" data-name="Path 87" d="M266,176.541a14.923,14.923,0,0,0-5.412-.941c-5.883,0-9.059,2.941-9.059,7.177,0,3.177,1.882,4.824,4.235,5.883s3.059,1.765,3.059,2.706c0,1.412-1.882,2.118-3.53,2.118a12.788,12.788,0,0,1-5.647-1.177l-.824-.353L248,196.777a19.076,19.076,0,0,0,6.706,1.177c6.236,0,10.353-2.941,10.353-7.412,0-2.471-1.529-4.353-5.059-5.883-2.118-1.059-3.412-1.647-3.412-2.706,0-.941,1.059-1.882,3.412-1.882a10.791,10.791,0,0,1,4.471.824l.588.235.941-4.588Z" transform="translate(-211.528)" fill="#293688"/>
                        <path id="Path_88" data-name="Path 88" d="M370.283,180.4c-1.412,0-2.471.118-3.059,1.529L358.4,201.577h6.353l1.177-3.53h7.53l.706,3.53h5.647L374.871,180.4Zm-2.706,14.118c.353-1.059,2.353-6.236,2.353-6.236s.471-1.294.824-2.118l.353,2s1.176,5.294,1.412,6.471h-4.941Z" transform="translate(-305.692 -4.094)" fill="#3c58bf"/>
                        <path id="Path_89" data-name="Path 89" d="M371.695,180.4c-1.412,0-2.471.118-3.059,1.529L358.4,201.577h6.353l1.176-3.53h7.53l.706,3.53h5.647L374.871,180.4Zm-4.118,14.118c.471-1.176,2.353-6.236,2.353-6.236s.471-1.294.824-2.118l.353,2s1.176,5.294,1.412,6.471h-4.941Z" transform="translate(-305.692 -4.094)" fill="#293688"/>
                        <path id="Path_90" data-name="Path 90" d="M60.024,195.907l-.588-3.059c-1.059-3.53-4.471-7.412-8.236-9.295l5.294,18.824h6.353l9.53-21.177H66.024Z" transform="translate(-43.67 -4.776)" fill="#3c58bf"/>
                        <path id="Path_91" data-name="Path 91" d="M60.024,195.907l-.588-3.059c-1.059-3.53-4.471-7.412-8.236-9.295l5.294,18.824h6.353l9.53-21.177H67.2Z" transform="translate(-43.67 -4.776)" fill="#293688"/>
                        <path id="Path_92" data-name="Path 92" d="M0,180.4l1.059.235c7.53,1.765,12.706,6.236,14.706,11.53l-2.118-10c-.353-1.412-1.412-1.765-2.706-1.765Z" transform="translate(0 -4.094)" fill="#ffbc00"/>
                        <path id="Path_93" data-name="Path 93" d="M0,180.4H0c7.53,1.765,13.765,6.353,15.765,11.648l-2-8.353a2.872,2.872,0,0,0-2.824-2.235Z" transform="translate(0 -4.094)" fill="#f7981d"/>
                        <path id="Path_94" data-name="Path 94" d="M0,180.4H0c7.53,1.765,13.765,6.353,15.765,11.648l-1.412-4.588c-.353-1.412-.824-2.824-2.471-3.412Z" transform="translate(0 -4.094)" fill="#ed7c00"/>
                        <g id="Group_34" data-name="Group 34" transform="translate(7.647 178.659)">
                          <path id="Path_95" data-name="Path 95" d="M66.589,208.165l-4-4-1.882,4.471-.471-2.941c-1.059-3.53-4.471-7.412-8.236-9.295l5.294,18.824h6.353Z" transform="translate(-52 -196.4)" fill="#051244"/>
                          <path id="Path_96" data-name="Path 96" d="M190.8,294.377l-5.059-5.177-.941,5.177Z" transform="translate(-165.27 -275.552)" fill="#051244"/>
                          <path id="Path_97" data-name="Path 97" d="M258.236,274.8h0c.471.471.706.824.588,1.294,0,1.412-1.882,2.118-3.53,2.118a12.788,12.788,0,0,1-5.647-1.177l-.824-.353L248,281.506a19.076,19.076,0,0,0,6.706,1.177c3.765,0,6.824-1.059,8.589-2.941Z" transform="translate(-219.175 -263.27)" fill="#051244"/>
                          <path id="Path_98" data-name="Path 98" d="M364,235.012h5.53l1.177-3.53h7.53l.706,3.53h5.647l-2-8.589L375.53,219.6l.353,1.882s1.177,5.294,1.412,6.471h-4.941c.471-1.177,2.353-6.236,2.353-6.236s.471-1.294.824-2.118" transform="translate(-318.116 -216.188)" fill="#051244"/>
                        </g>
                      </g>
                    </svg>
            </div>
          </div>
          <div class="col">
            <div class="card h-100 justify-content-center text-end">
                  <!-- MASTERCARD -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="52.269" height="31.361" viewBox="0 0 52.269 31.361">
                    <g id="mastercard" transform="translate(0 -100.8)">
                      <path id="Path_69" data-name="Path 69" d="M232.961,116.481a15.681,15.681,0,1,1-31.361,0h0a15.681,15.681,0,1,1,31.361,0Z" transform="translate(-180.692 0)" fill="#ffb600"/>
                      <path id="Path_70" data-name="Path 70" d="M217.281,100.8a15.721,15.721,0,0,1,15.681,15.681h0a15.681,15.681,0,0,1-31.361,0" transform="translate(-180.692)" fill="#f7981d"/>
                      <path id="Path_71" data-name="Path 71" d="M352.8,100.8a15.721,15.721,0,0,1,15.681,15.681h0A15.668,15.668,0,0,1,352.8,132.161" transform="translate(-316.212)" fill="#ff8500"/>
                      <path id="Path_72" data-name="Path 72" d="M15.515,100.8a15.681,15.681,0,0,0,.166,31.361A15.863,15.863,0,0,0,26.217,128.1h0a14.91,14.91,0,0,0,1.576-1.659H24.558A19.14,19.14,0,0,1,23.4,124.86h5.559a10.628,10.628,0,0,0,.913-1.659H22.484a9.888,9.888,0,0,1-.664-1.659h8.629a16.474,16.474,0,0,0,.83-4.978,21.416,21.416,0,0,0-.332-3.319H21.322a12.13,12.13,0,0,1,.415-1.659h8.629a9.888,9.888,0,0,0-.664-1.659H22.4a16.453,16.453,0,0,1,.913-1.659h5.559a7.89,7.89,0,0,0-1.244-1.659h-3.07a14.142,14.142,0,0,1,1.576-1.576A15.31,15.31,0,0,0,15.6,100.966C15.6,100.8,15.6,100.8,15.515,100.8Z" fill="#ff5050"/>
                      <path id="Path_73" data-name="Path 73" d="M0,117.915A15.668,15.668,0,0,0,15.681,133.6a15.863,15.863,0,0,0,10.537-4.065h0a14.909,14.909,0,0,0,1.576-1.659H24.558a19.137,19.137,0,0,1-1.162-1.576h5.559a10.628,10.628,0,0,0,.913-1.659H22.484a9.889,9.889,0,0,1-.664-1.659h8.629a16.474,16.474,0,0,0,.83-4.978,21.416,21.416,0,0,0-.332-3.319H21.322a12.129,12.129,0,0,1,.415-1.659h8.629a9.888,9.888,0,0,0-.664-1.659H22.4a16.453,16.453,0,0,1,.913-1.659h5.559a7.89,7.89,0,0,0-1.244-1.659h-3.07a14.14,14.14,0,0,1,1.576-1.576A15.31,15.31,0,0,0,15.6,102.4h-.083" transform="translate(0 -1.434)" fill="#e52836"/>
                      <path id="Path_74" data-name="Path 74" d="M149.766,133.6A15.863,15.863,0,0,0,160.3,129.53h0a14.91,14.91,0,0,0,1.576-1.659h-3.236a19.142,19.142,0,0,1-1.162-1.576h5.559a10.628,10.628,0,0,0,.913-1.659h-7.384a9.889,9.889,0,0,1-.664-1.659h8.629a16.475,16.475,0,0,0,.83-4.978,21.415,21.415,0,0,0-.332-3.319h-9.624a12.128,12.128,0,0,1,.415-1.659h8.629a9.889,9.889,0,0,0-.664-1.659h-7.3a16.455,16.455,0,0,1,.913-1.659h5.559a7.89,7.89,0,0,0-1.245-1.659h-3.07a14.142,14.142,0,0,1,1.576-1.576,15.31,15.31,0,0,0-10.537-4.065H149.6" transform="translate(-134.085 -1.434)" fill="#cb2026"/>
                    </g>
                  </svg>
            </div>
          </div>

          <!-- <div class="col">
            <div class="card h-100 justify-content-center text-end">
                  <svg xmlns="http://www.w3.org/2000/svg" width="29.999" height="35.919" viewBox="0 0 29.999 35.919">
                    <g id="paypal_1_" data-name="paypal (1)" transform="translate(-42.193)">
                      <path id="Path_99" data-name="Path 99" d="M69.321,9.28a12.342,12.342,0,0,0,.093-1.508A7.772,7.772,0,0,0,61.642,0H48.653A1.673,1.673,0,0,0,47,1.4L42.217,29.842a1.673,1.673,0,0,0,1.65,1.951h4.75a1.687,1.687,0,0,0,1.663-1.4l.022-.132h0L49.641,34.2A1.475,1.475,0,0,0,51.1,35.919h4.156a1.475,1.475,0,0,0,1.454-1.23l1.181-7.017a2.512,2.512,0,0,1,2.477-2.1h1.092A10.737,10.737,0,0,0,72.192,14.839,6.81,6.81,0,0,0,69.321,9.28Z" transform="translate(0)" fill="#002987"/>
                      <path id="Path_100" data-name="Path 100" d="M167.756,132.28a12.237,12.237,0,0,1-12.143,10.727h-3.625a1.818,1.818,0,0,0-1.746,1.315L148.076,157.2a1.475,1.475,0,0,0,1.454,1.72h4.156a1.475,1.475,0,0,0,1.454-1.23l1.181-7.017a2.512,2.512,0,0,1,2.477-2.1h1.092a10.737,10.737,0,0,0,10.737-10.737h0A6.81,6.81,0,0,0,167.756,132.28Z" transform="translate(-98.435 -123)" fill="#0085cc"/>
                      <path id="Path_101" data-name="Path 101" d="M180.979,126.292H184.6a12.237,12.237,0,0,0,12.143-10.727A6.787,6.787,0,0,0,192.8,114.3h-9.46a2.206,2.206,0,0,0-2.176,1.84l-1.929,11.464A1.818,1.818,0,0,1,180.979,126.292Z" transform="translate(-127.426 -106.284)" fill="#00186a"/>
                    </g>
                  </svg>
            </div>
          </div> -->

        </div>
      </div>

    </div>

    <div class="row mt-5">
      <div class="col-xs-12 col-md-2">
        <div class="card h-100 justify-content-center text-xs-center text-sm-start plus_18">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
            <g id="_x34_4-18Plus_movie" transform="translate(-0.001)">
              <g id="Group_29" data-name="Group 29" transform="translate(12.68 16.461)">
                <path id="Path_58" data-name="Path 58" d="M86.53,125.323V111.749H83.392V108.27H90.86v17.053Z" transform="translate(-83.392 -108.27)" fill="#d97070"/>
              </g>
              <g id="Group_30" data-name="Group 30" transform="translate(23.359 16.291)">
                <path id="Path_59" data-name="Path 59" d="M166.693,111.723a3.985,3.985,0,0,1-2.141,3.527,4.663,4.663,0,0,1,3.041,4.306c0,3.065-2.725,5.011-6.982,5.011s-6.982-1.922-6.982-4.938a4.759,4.759,0,0,1,3.235-4.379,4.287,4.287,0,0,1-2.36-3.649c0-2.725,2.384-4.452,6.082-4.452C164.334,107.15,166.693,108.926,166.693,111.723Zm-8.977,7.468c0,1.435,1.022,2.238,2.895,2.238s2.919-.778,2.919-2.238c0-1.411-1.046-2.214-2.919-2.214S157.717,117.781,157.717,119.192Zm.438-7.225c0,1.217.876,1.9,2.457,1.9s2.457-.681,2.457-1.9c0-1.265-.876-1.97-2.457-1.97S158.155,110.7,158.155,111.967Z" transform="translate(-153.63 -107.15)" fill="#d97070"/>
              </g>
              <g id="Group_31" data-name="Group 31" transform="translate(0.001)">
                <path id="Path_60" data-name="Path 60" d="M44.527,18.386A20.6,20.6,0,1,1,31.614,5.474V.887a24.982,24.982,0,1,0,17.5,17.5Z" transform="translate(-0.001)" fill="#d97070"/>
              </g>
              <g id="Group_32" data-name="Group 32" transform="translate(35.068 1.913)">
                <path id="Path_61" data-name="Path 61" d="M238.859,17.376V12.582h-3.447v4.794h-4.765v3.447h4.765v4.793h3.447V20.822h4.793V17.376Z" transform="translate(-230.647 -12.582)" fill="#d97070"/>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div class="col-xs-12 col">
        <div class="card h-100 justify-content-center text-center text-md-end mb-5">
                  Competition Mania Ltd. &#169; 2021 Company Registration: 12792356
        </div>
      </div>
    </div>

    <Messenger/>

  </footer>



</template>

<script>

import Messenger from '../global/Messenger';

export default {
  components: {
    Messenger
  }
}
</script>